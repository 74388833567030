import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Quito extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Quito">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623973/ecuador/backgrounds/quito4.jpg" alt=""/>
      </div>

      <p className="header-title">QUITO CITY TOUR +<br/> MIDDLE OF THE WORLD</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION</span>
              <p>After breakfast, our guide will pick you up from your hotel and take you to the old town of
              Quito. The tour will begin at the Independence Square, where are the Presidential Palace,
              the Cathedral and the municipal building. Also we visit the Church of La Compania and San
              Francisco.
              At the end, we will drive to one of the highest viewpoints in the city, "El Panecillo" hill with a
              spectacular view of Quito.
              Then we go to the north of the city, a few kilometers from the city of Quito is the Middle of the
              world, we will visit the museum Inti Nan, an interesting museum that show the experiences
              that occur only in this part of the planet, after we will stop to see the historical monument that
              symbolizing the French Geodesic Mission, the same that determines the equator line in the
              XVIII century. 
              </p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go back</Link>
          </div>

      </div>
      </div>
    );
  }
}

export default Quito;