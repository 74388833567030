import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Cuenca extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Cuenca">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623876/ecuador/backgrounds/cuenca2.jpg" alt=""/>
      </div>

      <p className="header-title">CUENCA</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>Cuenca is the capital of the Azuay Province. It is widely regarded as the most European city in the country of Ecuador due to its 16th and 17th century era Spanish colonial architecture resembling cities and architecture throughout Spain. The city of Cuenca is located in the highlands of Ecuador at about 2,560 metres (8,400 feet) above sea level, with an urban population of approximately 400,000 rising to 700,000 inhabitants in the larger metropolitan area. The centre of the city is listed as a UNESCO World Heritage Site due to its many historical buildings.</p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go Back</Link>
          </div>

      </div>
      </div>
    );
  }
}

export default Cuenca;