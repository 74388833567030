import React, { Component } from 'react';
import '../../App.css';

import { Link } from 'react-router-dom'

class SouthAmerica extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="SouthAmerica">
            <div className="top-header">
            <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624328/sa/banner.png" alt=""/>
              <div>
                <p id="title-hotel">SOUTH AMERICA</p>
                <div id="subtitle-container">
                  <span id="price-text">
                    Peru / Patagonia / Chile
                  </span>
                </div>
              </div>
            </div>

            <div className="middle-section">
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624353/sa/thumbnails/peru1.jpg" alt=""/>
                <label><Link to="/peru1">CUSCO, SACRED VALLEY, SHORT INCA TRAIL, MACHU PICCHU AND PUNO</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624347/sa/thumbnails/peru2.jpg" alt=""/>
                <label><Link to="/peru2">CUSCO, SACRED VALLEY, MACHU PICCHU</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624351/sa/thumbnails/peru3.jpg" alt=""/>
                <label><Link to="/peru3">LIMA, CUSCO, MACHU PICCHU</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624354/sa/thumbnails/easter.jpg" alt=""/>
                <label><Link to="/easter">EASTER ISLAND</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624350/sa/thumbnails/go6.jpg" alt=""/>
                <label><Link to="/go6">ECUADOR, GALAPAGOS, PERU</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624351/sa/thumbnails/atacama.jpg" alt=""/>
                <label><Link to="/atacama">ATACAMA DESERT</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624353/sa/thumbnails/calafate.jpg" alt=""/>
                <label><Link to="/calafate">CALAFATE</Link></label>
              </div>
              
            </div>
      </div>
    );
  }
}

export default SouthAmerica;