import React, { Component } from 'react';
import '../../App.css';
import ScrollAnimation from 'react-animate-on-scroll';
import iata from '../../img/IATA.png'
import global from '../../img/global.png'
import viexpa from '../../img/logoV.png'
import paypal from '../../img/paypal.png'


class contacto extends Component {
    render() {
        return (
            <div className="cruise" id="contact">
                <div id="fullsectioncruise">
                    <div id="container">
                    <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
                        <div id="transparent-container">
                        <img src={viexpa} alt="" id="viexp"/>
                        <div id="logos-contact">
                            <img src={iata} alt="" id="iata"/>
                            <img src={global} alt="" id="global"/>
                        </div><br/>
                            {/* <span id="title">VIEXPA</span><br/>
                            <span id="subtitle">TRAVEL AGENCY</span> */}
                            <p id="data">
                                Cumbayá Ecuador<br />
                                +593 99 914 4148<br />
                                amoncayo@viexpa.com
                            </p>
                            <div id="social">
                            {/* <a href="#"><img src="https://www.paypalobjects.com/webstatic/es_MX/mktg/logos-buttons/redesign/btn_13.png" alt="PayPal Credit" /></a><br/> */}
                            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                                <input type="hidden" name="cmd" value="_s-xclick"/>
                                <input type="hidden" name="hosted_button_id" value="MAB4VXUTSX6Q2"/>
                                <input type="image" src={paypal} border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"/>
                                <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
                            </form>
                                <a><i className="fab fa-cc-mastercard"></i></a>
                                <a><i className="fab fa-cc-visa"></i></a>
                                <a><i className="fab fa-cc-discover"></i></a>
                            </div><br/>
                            <span id="follow">Follow Us!</span>
                            <div id="social">
                                <a href="https://www.facebook.com/viexpatravel1/" rel="noopener noreferrer" target="_blank"><i className="fab fa-facebook"></i></a>
                                <a href="https://www.youtube.com/channel/UC3DER9k1cpHTrnAdbRrH7cw" rel="noopener noreferrer" target="_blank"><i className="fab fa-youtube-square"></i></a>
                                <a href="https://www.instagram.com/viexpa/" rel="noopener noreferrer" target="_blank"><i className="fab fa-instagram"></i></a>
                                <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank">
                                <i className="fab fa-whatsapp"></i></a>
                            </div>
                        </div>
                    </ScrollAnimation>
                    </div>
                </div>
            </div>
        );
    }
}

export default contacto;
