import React, { Component } from 'react';
import '../../App.css';

import { Link } from 'react-router-dom'

import Slider from "react-slick";

class granmajestic extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    var settings = {
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      useCSS: true,
      centerMode: true,
      focusOnSelect: true,
      dots: false
    };
    return (
      <div className="Hotel">
            <div id="fullscreen">
              <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621987/cruceros/backgrounds/granmajestic.jpg" alt='' id='ship' /> 
              <Link to="/infinity" class="fas fa-angle-right"></Link>
              <Link to="/grace" class="fas fa-angle-left"></Link>
              <div id="text-container">
                <p id="title-hotel">GRAN MAJESTIC YACHT</p>
                <div id="subtitle-container">
                  <span id="texto-descripcion">
                  16 guests Galapagos Yacht
                  </span>
                </div>
              </div>

            </div>

            <div id="separacion-ficha"></div>

            <div id="slider-cruiser">
            <Slider id="slidershow-cruiser" {...settings}>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622941/cruceros/carrusel/granmajestic/11.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622941/cruceros/carrusel/granmajestic/10.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622939/cruceros/carrusel/granmajestic/8.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622938/cruceros/carrusel/granmajestic/2.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622942/cruceros/carrusel/granmajestic/9.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622942/cruceros/carrusel/granmajestic/5.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622941/cruceros/carrusel/granmajestic/6.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622941/cruceros/carrusel/granmajestic/4.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622938/cruceros/carrusel/granmajestic/3.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622937/cruceros/carrusel/granmajestic/7.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622935/cruceros/carrusel/granmajestic/1.jpg" alt=''/>
              </div>
            </Slider>
            </div><br/><br/><br/>

            <div id="ficha-tecnica">
              <span>THE YACHT:</span>
              <p>Grand Majestic is the only modern mega-yacht cruising the Galapagos. This elegantly sporty yacht is the fastest in the Islands
               and offers high-quality service, a Master Suite and luxurious social areas. Ideal for private charters.
                Ideal for private charters. The only modern mega-yacht in Galapagos. The fastest yacht in the Islands. Large master suite available
              </p><br/><br/>
              
              <br/>
              <span id="offset-x">PRICE:</span>
              <div id="details-pdf">
                  <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
                  </div>
                  <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
                  </div>
            </div>
    
      </div>
    );
  }
}

export default granmajestic;