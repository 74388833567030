import React, { Component } from 'react';
import '../../App.css';

import logoimg from '../../img/logoimg.png'
import shapeV from '../../img/bordeVerde.png'

class header extends Component {

  mostrarPromo = ()=>{
    document.getElementById('pop').style.display='flex'
  }
  mostrarPromo2 = ()=>{
    //document.getElementById('pop2').style.display='flex'
    window.open('https://www.e-agencias.com.ec/viexpa', '_blank');
  }
  mostrarCuadro = ()=>{
    document.getElementById('cuadro').style.visibility = "visible"
    document.getElementById('cuadro-min').style.visibility = "hidden"
  }

  render() {
    return (
      <div className="header">
        
        <header>
            <div className="container-header">
              <img src={logoimg} alt=""/>
              <div id="promoAzul" onClick={()=>this.mostrarPromo()}>
                <img src={shapeV} alt="" id="bordeVerde"/>
                <span>GALAPAGOS<br/> LAST MINUTE PRICE</span>
              </div>
            </div>
        </header>

      </div>
    );
  }
}

export default header;
