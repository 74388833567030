import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Sacha extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Baños">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621709/amazon%C3%ADa/sacha.jpg" alt=""/>
      </div>

      <p className="header-title">SACHA LODGE</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>The Amazon might seem like a world away, but Sacha Lodge is easily accessible from Quito. After an easy 25-minutes flight from Quito and a two-hour journey speeding up the Napo River in a motorized canoe leaving civilization far behind, by lunchtime you will be in a hammock listening to the chatter of the tamarind monkeys. Remote yet accessible, unspoiled yet comfortable, the ecolodge has protected the fabulous wildlife here for more than 20 years, enabling it to flourish. Our all-inclusive jungle lodge is the ideal base for you and your family to explore the fascinating rainforest in small groups, led by expert guides.</p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go Back</Link>
          </div>

      </div>

      </div>
    );
  }
}

export default Sacha;