import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Peru3 extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Peru3">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624389/sa/backgrounds/lima.jpg" alt=""/>
      </div>

      <p className="header-title">LIMA, CUSCO, MACHU PICCHU</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>
                <b>CUSCO:</b> Seductive, striking and natural, Cusco’s history lives in its streets, squares, valleys and towns.Stunning destinations and examples of fine engineering by Inca stonemasons can be seen in Choquequirao, Saysayhuamán, Kenko, Tambomachay, Ollantaytambo and Machu Picchu, the Inca jewel built with the wisdom of the ancient Peruvians in an ecological environment. Captivating landscapes such as the Sacred Valley, where the mountains are clothed by terraces.
                Pictures villages where the past forms part of the present. Cusco really is the birthplace of the world.
                <br/><br/>
                <b>MACHU PICCHU:</b> Machu Picchu is an Incan city surrounded by temples, terraces and water channels, built on a mountaintop. It was built with huge blocks of stone joined to each other without any mortar. Today it has been designated cultural heritage of humanity in recognition of its political, religious and administrative importance during the age of the Incas.
                <br/><br/>
                <b>LIMA:</b> In the north is the city of Caral, the oldest civilization of America, and to the south is the Archaeological Complex of Pachacamac. Lima's history predates the colonial presence in the country. The establishment of the viceroyalty transformed the city into the main political and administrative center of South America. During this period, significant churches, monasteries, mansions and balconies were built. The arrival of modernity didn’t transform the historic center, which is recognized as a World Heritage Site.
                Museums with great works of art, archaeological sites, beaches, the boardwalk, valleys, natural reserves, the nightlife, the thrill of adventure sports, and the exquisite cuisine gives Peru’s capital an authentic personality and makes tourism in Lima a unique experience in the country.
              </p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/south-america" id="volver">
              <i className="fas fa-arrow-left"></i> Go back</Link>
          </div>

      </div>
      </div>
    );
  }
}

export default Peru3;