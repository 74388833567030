import React, { Component } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';

import more from '../../img/more.png'
import division from '../../img/division.png'

import hotel1 from '../../img/amazonia.jpg'
import hotel3 from '../../img/cotopaxi.jpg'


class hotel extends Component {


    render() {
        return (
            <div className="cruise">
                <div id="fullsectioncruise">

                    <div id="container">
                    <ScrollAnimation animateIn="slideInLeft" duration={1.5} animateOnce={true}>
                        <div id="img-container">
                            <img src={hotel1} alt='' width="50%" height="100%"/>
                            <img src={hotel3} alt='' width="50%" height="100%"/>
                        </div>
                    </ScrollAnimation>

                        <div id="info-container">
                            <span>TRAVEL ECUADOR<br/>
                                FALL IN LOVE AGAIN</span>

                            <p id="infohotel">All in one place, all so close. Ecuador is the most diverse country in the globe, situated in the middle of the world with an amazing climate. Plan your perfect adventure and visit the four regions Ecuador has to offer. The Amazon, Coastline and the Andes are as close as a 4 hour land ride. A lot to offer to it's visitors; a chance to fall in love again.</p>
                            <Link to="/ecuador">
                                <img id="more" src={more} alt="" width="40" height="58"/>
                            </Link><br/>
                            <img id="division2" src={division} alt=""/>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}

export default hotel;