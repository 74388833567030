import React, { Component } from 'react';
import '../../App.css';

import ScrollAnimation from 'react-animate-on-scroll';

import * as contentful from 'contentful'

import Header from './header'
import Banner from './banner'
import Cruise from './cruise'
import Hotel from './hotel'
import Hotel2 from './hotel2'
import Contacto from './contacto'

class Inicio extends Component {

  componentDidMount() {

    document.getElementById("pop2").style.display = "none"

    window.scrollTo(0, 0);

    const client = contentful.createClient({
      space: '0dhdai0q1ogp',
      accessToken: '76426a08f44e20846fd9bbad14db23509db9a6b2cf102f3f9ab17a5612958b1a'
    })

    var imageURL

    client.getAssets()
    .then(function (assets) {
      assets.items.map(function(asset){
        imageURL = 'https:' + asset.fields.file.url;
        
        var imagesDiv = document.getElementById('image-pop');

        assets.items.forEach(function (asset) {
            var imageURL = 'https:' + asset.fields.file.url;
            var imageDiv = document.createElement("div");
            var imageFile = document.createElement("img");
            imageFile.src = imageURL;
            imageDiv.appendChild(imageFile);
            imagesDiv.appendChild(imageDiv);
        });
      });
    })
    .catch(function (e) {
      console.log(e);
      });


      const client2 = contentful.createClient({
        space: '3w6dtrmcdjw6',
        accessToken: '3e81660285812bac0baecad03e700678f29a644e91d65d78900b6e70e995e7d8'
      })

      var imageURL2

      client2.getAssets()
      .then(function (assets2) {
        assets2.items.map(function(asset2){
          imageURL2 = 'https:' + asset2.fields.file.url;
          
          var imagesDiv2 = document.getElementById('image-pop2');

          assets2.items.forEach(function (asset2) {
              var imageURL2 = 'https:' + asset2.fields.file.url;
              var imageDiv2 = document.createElement("div");
              var imageFile2 = document.createElement("img");
              imageFile2.src = imageURL2;
              imageDiv2.appendChild(imageFile2);
              imagesDiv2.appendChild(imageDiv2);
          });
        });
      })
      .catch(function (e) {
        console.log(e);
        });
    }

    closePop = ()=>{
      document.getElementById('pop').style.display="none";
    }

    closePop2 = ()=>{
      document.getElementById('pop2').style.display="none";
    }

  render() {
    return (
      <div className="Inicio">

        <div className="pop-container" id="pop">
        <ScrollAnimation animateIn="zoomIn" duration={0.2} delay={1000} animateOnce={true}>
          
            <div className="image-pop">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank" id="image-pop">
              </a>
              <i className="fas fa-times-circle" id="close-pop" onClick={() => this.closePop()}></i>
            </div>
        </ScrollAnimation>
        </div>

        <div className="pop-container" id="pop2">
        <ScrollAnimation animateIn="zoomIn" duration={0.2} delay={1000} animateOnce={true}>
          <div className="image-pop">
            <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank" id="image-pop2">
            </a>
            <i className="fas fa-times-circle" id="close-pop" onClick={() => this.closePop2()}></i>
          </div>
        </ScrollAnimation>
        </div>


        <Header/>
        <Banner/>
        <Cruise/>
        <Hotel/>
        <Hotel2/>
        <Contacto/>
        <div className="copyright">
        <span>© 2018 Viexpa. All Rights Reserved</span>
        </div>
      </div>
    );
  }
}

export default Inicio;