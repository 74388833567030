import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Selva extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Baños">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621706/amazon%C3%ADa/sj2.jpg" alt=""/>
      </div>

      <p className="header-title">LA SELVA LODGE & SPA</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>La Selva Amazon Lodge & Spa was completely remodeled and reopened in July of 2012 and has since become the premier eco lodge in the Ecuadorian Amazon. La Selva Amazon Ecolodge & Spa is located in a rainforest paradise bordering Yasuni National Park in one of the most biodiverse areas of the planet. At La Selva, your day is centered on excursions, in which you and your group, accompanied by an English-speaking naturalist guide and indigenous guide, explore the trails, lagoon and rivers of the Amazon rain forest outside the doorstep of the lodge. These excursions are included in the price of your stay.</p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go Back</Link>
          </div>

      </div>

      </div>
    );
  }
}

export default Selva;