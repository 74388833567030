import React, { Component } from 'react';
import '../../App.css';

import { Link } from 'react-router-dom'

import Slider from "react-slick";

import divein from '../../img/divein.png'

class nortada extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    var settings = {
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      useCSS: true,
      centerMode: true,
      focusOnSelect: true,
      dots: false
    };
    return (
      <div className="Hotel">
            <div id="fullscreen">
              <img src={divein} alt="" id="divein"/>
              <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621952/cruceros/backgrounds/nortada.jpg" alt='' id='ship' /> 
              <Link to="/ocean" class="fas fa-angle-right"></Link>
              <Link to="/natural" class="fas fa-angle-left"></Link>
              <div id="text-container">
                <p id="title-hotel">NORTADA Dive - In</p>
                <div id="subtitle-container">
                  <span id="texto-descripcion">
                  8 divers Galapagos Yacht
                  </span>
                </div>
              </div>

            </div>

            <div id="separacion-ficha"></div>

            <div id="slider-cruiser">
            <Slider id="slidershow-cruiser" {...settings}>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623153/cruceros/carrusel/nortada/8.png" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623151/cruceros/carrusel/nortada/7.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623151/cruceros/carrusel/nortada/4.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623151/cruceros/carrusel/nortada/2.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623151/cruceros/carrusel/nortada/6.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623150/cruceros/carrusel/nortada/3.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623150/cruceros/carrusel/nortada/5.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623149/cruceros/carrusel/nortada/1.jpg" alt=''/>
              </div>
            </Slider>
            </div><br/><br/><br/>

            <div id="ficha-tecnica">
              <span>THE YACHT:</span>
              <p>Built in France by Naval Builder Guy Couach, Nortada is a completely rebuilt and refit private live-aboard charter boat for small groups of up to 8 divers. Nortada offers four lower deck, fully air conditioned cabins with two single lower berths, private heads, lockers and drawers. The scuba diving platform has ample benches, outside showers, camera tables and rebreather rinsing facilities.  In the upper deck a sun deck is next to the fly bridge.
              </p><br/><br/>
              
              <br/>
              <div id="details-pdf">
                  <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
                  </div>
                  <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
                  </div>
            </div>
    
      </div>
    );
  }
}

export default nortada;