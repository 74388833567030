import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Mindo extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Mindo">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623896/ecuador/backgrounds/mindo.jpg" alt=""/>
      </div>

      <p className="header-title">MINDO</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION</span>
              <p>Mindo is world famous for birdwatching, an unforgettable tourist destination for many foreign
              and national tourists, is a small town located 1 hour and 30 minutes approximately in the
              middle of the cloud forest to the Northwest Quito, with 19,200 protected hectares surround
              Mindo doing so one of the most biodiverse places on the planet
              When arriving at the destination, the first visit is the visit to the Mariposario, where we will know
              about these beautiful insects, after coordinating with the guide and knowing the interest of
              the passengers you can perform activities such as visiting waterfalls, tubing or canopy
              (activities paid directly by the passenger at the destination).</p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go back</Link>
          </div>

      </div>
      </div>
    );
  }
}

export default Mindo;