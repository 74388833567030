import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Mangrove extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Baños">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623879/ecuador/backgrounds/mangrove.jpg" alt=""/>
      </div>

      <p className="header-title">RED MANGROVE HOTEL</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>Red Mangrove Hotel is an exclusive ecological experience. Most noteworthy are the oceanfront views of Academy Bay and the naturally surrounding mangroves. As a result, the mangroves give off the sensation of exclusion from the rest of the island. The hotel is in Puerto Ayora, but it doesn’t feel like it. Guests can choose between 17 different rooms with an Ocean View or an exclusive look at the Mangrove Ecosystem.  There are 5 Suites, 6 Superiors, and 12 Superior accommodations in total.</p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go Back</Link>
          </div>

      </div>

      </div>
    );
  }
}

export default Mangrove;