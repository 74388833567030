import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Mashpi extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Mashpi">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623902/ecuador/backgrounds/mashpi.jpg" alt=""/>
      </div>

      <p className="header-title">MASHPI</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>Mashpi Lodge is the place to encounter 500 species of birds; trees and frogs found nowhere in the world; crashing waterfalls and mysterious felines. Dare yourself to trek down hidden paths, splash through rivers, rise at dawn to spot rare birds, or fly high above it all aboard our Dragonfly.</p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <br/><br/>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go back</Link>
          </div>

      </div>
      </div>
    );
  }
}

export default Mashpi;