import React, { Component } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom'

import division from '../../img/division.png'

// Slick Carousel
import Slider from "react-slick";

class cruise extends Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            useCSS: true
          };
        return (
            <div className="cruise">
                <div id="fullsectioncruise">

                    <div id="white-container">
                        <span>CRUISE SHIPS - GALAPAGOS</span>
                        <br/><p>
                        Discover The Galapagos aboard the vessel that suits your planned trip and needs. Theres’s no better way to discover the enchanted islands than to sail on a daily basis aboard cruise ship while you discover the most seducing Archipelago, with its blue and emerald sea, white pebbles and exotic marine life.
                        </p>

                        <Slider {...settings}>
                            <div>
                                <img id="division" src={division} alt="" />
                                <br/><span>Alya Catamaran</span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <Link to="/aliacatamaran">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621826/cruceros/Alia-Catamaran.jpg" alt='' /></div>
                                </Link>
                            </div>
                            <div>
                                <img id="division" src={division} alt="" />
                                <br/><span>Coral I & II</span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="far fa-star"></i>
                                </div>
                                <Link to="/coral">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621827/cruceros/Coral.jpg" alt=''/></div>
                                </Link>
                            </div>
                            <div>
                                <img id="division" src={division} alt="" />
                                <br/><span>Cormorant Catamaran</span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <Link to="/cormorant">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621835/cruceros/Cormorant-Catamaran.jpg" alt=''/></div>
                                </Link>
                            </div>
                            <div>
                                <img id="division" src={division} alt="" />
                                <br/><span>Evolution Yacht</span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <Link to="/evolution">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621829/cruceros/Evolution-yacht.jpg" alt=''/></div>
                                </Link>
                            </div>
                            <div>
                                <img id="division" src={division} alt="" />
                                <br/><span>Galápagos Sky</span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="far fa-star"></i>
                                </div>
                                <Link to="/galapagos">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621825/cruceros/Galapagos-Sky.jpg" alt=''/></div>
                                </Link>
                            </div>
                            <div>
                                <img id="division" src={division} alt="" />
                                <br/><span>Grace Yacht</span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <Link to="/grace">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621834/cruceros/Grace-yacht.jpg" alt=''/></div>
                                </Link>
                            </div>
                            <div>
                                <img id="division" src={division} alt="" />
                                <br/><span>Gran Majestic Yacht</span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <Link to="/gran-majestic">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621830/cruceros/Gran-Majestic-yacht.jpg" alt=''/></div>
                                </Link>
                            </div>
                            <div>
                                <img id="division" src={division} alt="" />
                                <br/><span>Infinity Yacht </span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <Link to="/infinity">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621831/cruceros/Infinity-yacht.jpg" alt=''/></div>
                                </Link>
                            </div>
                            <div>
                                <img id="division" src={division} alt="" />
                                <br/><span>Isabela Yacht</span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <Link to="/isabela">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621842/cruceros/Isabela-yacht.jpg" alt=''/></div>
                                </Link>
                            </div>
                            <div>
                                <img id="division" src={division} alt="" />
                                <br/><span>La Pinta Yacht</span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <Link to="/pinta">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621840/cruceros/La-Pinta-yacht.jpg" alt=''/></div>
                                </Link>
                            </div>
                            <div>
                                <img id="division" src={division} alt="" />
                                <br/><span>Legend Cruise</span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <Link to="/legend">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621834/cruceros/LEGEND-cruise.jpg" alt=''/></div>
                                </Link>
                            </div>
                            <div>
                                <img id="division" src={division} alt="" />
                                <br/><span>Natural Paradise Yacht</span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <Link to="/natural">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621840/cruceros/Natural-Paradise-yacht.jpg" alt=''/></div>
                                </Link>
                            </div>
                            <div>
                                <img id="division" src={division} alt="" /> 
                                <br/><span>Nortada Dive-in Yacht</span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="far fa-star"></i>
                                </div>
                                <Link to="/nortada">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621842/cruceros/Nortada.jpg" alt=''/></div>
                                </Link>
                            </div>
                            <div>
                                <img id="division" src={division} alt="" />
                                <br/><span>Ocean Spray Catamaran</span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <Link to="/ocean">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621842/cruceros/Ocean-Spray-Catamaran.jpg" alt=''/></div>
                                </Link>
                            </div>
                            <div>
                                <img id="division" src={division} alt="" />
                                <br/><span>Petrel Catamaran</span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <Link to="/petrel">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621840/cruceros/Petrel-catamaran.jpg" alt=''/></div>
                                </Link>
                            </div>
                            <div>
                                <img id="division" src={division} alt="" />
                                <br/><span>Reina Silvia</span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="far fa-star"></i>
                                </div>
                                <Link to="/reina">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621842/cruceros/Reina-Silvia.jpg" alt=''/></div>
                                </Link>
                            </div>
                            <div>
                                <img id="division" src={division} alt="" />
                                <br/><span>Santa Cruz</span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <Link to="/santa-cruz">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621843/cruceros/Santa-Cruz-cruise.jpg" alt=''/></div>
                                </Link>
                            </div>
                            <div>
                                <img id="division" src={division} alt="" />
                                <br/><span>Camila</span>
                                <div className="stars">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <Link to="/camila">
                                    <div><img id="img-slider" src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621834/cruceros/camila.jpg" alt=''/></div>
                                </Link>
                            </div>
                        </Slider>

                    </div>

                </div>
            </div>
        );
    }
}

export default cruise;