import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Anakonda extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Baños">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621692/amazon%C3%ADa/anakonda2.jpg" alt=""/>
      </div>

      <p className="header-title">ANAKONDA AMAZON CRUISES</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>The Anakonda Amazon River Cruise is the only luxury ship in the Ecuadorian Amazon Rainforest. It was built to make the experience of the Amazon the most comprehensive and unique adventure possible! Its eighteen suites feature ample sitting areas and large and comfortable beds. The vessel comes complete with an expansive observation deck, an outdoor Jacuzzi and one al fresco lounge; ideal for cocktails, relaxation, reading or simply enjoying the spectacular view. The vessel also features 3 canoes for guest transportation to land excursions and activities and also 10 kayaks. The Anakonda offers hammocks, naturalist tour leaders and a travel concierge, who will go out of his way to personalize your experience, making the Anakonda Amazon River Cruise a savory, unforgettably and exciting treat!</p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go Back</Link>
          </div>

      </div>

      </div>
    );
  }
}

export default Anakonda;