import React, { Component } from 'react';
import '../../App.css';

import { Link } from 'react-router-dom'

import Slider from "react-slick";

class aliacatamaran extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    var settings = {
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      useCSS: true,
      centerMode: true,
      focusOnSelect: true,
      dots: false
    };
    return (
      <div className="Hotel">
            <div id="fullscreen">
              <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621956/cruceros/backgrounds/alya.jpg" alt='' id='ship' /> 

              <Link to="/coral" class="fas fa-angle-right"></Link>
              <Link to="/camila" class="fas fa-angle-left"></Link>

              <div id="text-container">
                <p id="title-hotel">ALYA CATAMARAN</p>
                <div id="subtitle-container">
                  <span id="texto-descripcion">
                  16 guests Galapagos Yacht
                  </span><br/>
                </div>
              </div>

            </div>

            <div id="separacion-ficha"></div>

            <div id="slider-cruiser">
            <Slider id="slidershow-cruiser" {...settings}>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622659/cruceros/carrusel/alya/3.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622658/cruceros/carrusel/alya/4.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622657/cruceros/carrusel/alya/2.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622655/cruceros/carrusel/alya/1.jpg" alt=''/>
              </div>
            </Slider>
            </div><br/><br/><br/>

            <div id="ficha-tecnica">
              <span id='offset-x-2'>THE CATAMARAN:</span>
              <p>• 9 comfortable cabins, 8 of them with private balcony.
                 • 4 cabins in the upper deck and 5 in the main deck, all beds can be double or twin. Private facilities
                 in each cabin: private bathrooms, cold/hot water; air conditioned, hair dryer, telephone for internal
                 communication on board, safe box, and high speaker. 
                  Library, DVD service, dining room, bar with a variety of drinks and a sundeck with a panoramic Jacuzzi.</p>
              
              <br/><br/>
                  <div id="details-pdf">
                  <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp"></i> Chat with us</a>
                  </div>
                  <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us!</a>
                  </div>
            </div>
    
      </div>
    );
  }
}

export default aliacatamaran;