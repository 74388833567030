import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Calafate extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Calafate">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536644576/sa/backgrounds/0029124267.jpg" alt=""/>
      </div>

      <p className="header-title">CALAFATE</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>El Calafate, declared national capital of the glaciers, is
              the starting point to visit this region.
              Settled on the southern shore of Lago Argentino, Santa
              Cruz, it has the ideal tourist facilities, joining the natural
              environment with all the necessary comfort for a
              pleasant stay. </p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/south-america" id="volver">
              <i className="fas fa-arrow-left"></i> Go back</Link>
          </div>

      </div>
      </div>
    );
  }
}

export default Calafate;