import React, { Component } from 'react';
import '../../App.css';

import isotipo from '../../img/iso-about.png'
import firma from '../../img/firma-alvaro.png'

class About extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Hotel">
        <div className="about">
            <div>
            <img src={isotipo} alt="" id="isotipo-about"/>  
            <p>
            Viexpa, the Galapagos expert.
            <br/><br/><br/><br/>
            Welcome aboard, see you soon.</p><br/><br/>
            <img src={firma} alt="" id="firma-about"/>
            <p id="texto-firma">
            Alvaro Moncayo<br/>General Manager.
            </p></div>
        </div>
      </div>
    );
  }
}

export default About;