import React, { Component } from 'react';
import '../../App.css';

import { Link } from 'react-router-dom'

import Slider from "react-slick";

import divein from '../../img/divein.png'

class galapagos extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    var settings = {
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      useCSS: true,
      centerMode: true,
      focusOnSelect: true,
      dots: false
    };
    return (
      <div className="Hotel">
            <div id="fullscreen">
              <img src={divein} alt="" id="divein"/>
              <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621958/cruceros/backgrounds/sky2.jpg" alt='' id='ship' /> 
              <Link to="/grace" class="fas fa-angle-right"></Link>
              <Link to="/evolution" class="fas fa-angle-left"></Link>
              <div id="text-container">
                <p id="title-hotel">GALAPAGOS Sky - Dive In</p>
                <div id="subtitle-container">
                  <span id="texto-descripcion">
                  16 divers Galapagos Yacht
                  </span>
                </div>
              </div>

            </div>

            <div id="separacion-ficha"></div>

            <div id="slider-cruiser">
            <Slider id="slidershow-cruiser" {...settings}>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623607/cruceros/carrusel/sky/9.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623607/cruceros/carrusel/sky/10.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623606/cruceros/carrusel/sky/3.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623605/cruceros/carrusel/sky/8.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623604/cruceros/carrusel/sky/1.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623603/cruceros/carrusel/sky/2.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623602/cruceros/carrusel/sky/5.jpg" alt='' />
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623602/cruceros/carrusel/sky/7.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623601/cruceros/carrusel/sky/4.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623597/cruceros/carrusel/sky/6.jpg" alt=''/>
              </div>
            </Slider>
            </div><br/><br/><br/>

            <div id="ficha-tecnica">
              <span>THE YACHT:</span>
              <p>Luxury Galapagos Liveaboard  16 passenger live-aboard yacht providing thousands of divers with high octane, extraordinary Galapagos diving experiences. This yacht also offers a full line-up of luxury accommodations, excellent food, “extra touch” amenities, and a friendly, helpful staff. Discover the Magic of Galapagos Diving & its marine Sanctuary.
              </p><br/><br/>
              
              <br/>
              <div id="details-pdf">
                  <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
                  </div>
                  <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
                  </div>
            </div>
    
      </div>
    );
  }
}

export default galapagos;