import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Zuleta extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Zuleta">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623973/ecuador/backgrounds/zuleta2.jpg" alt=""/>
      </div>

      <p className="header-title">ZULETA</p>

      <div className="ecuador-pack-container">

      <div id="ficha-tecnica">
          <span id='offset-x-2'>DESCRIPTION:</span>
          <p>Stunning 17th century hotel and working-farm of former Ecuador President Galo Plaza Lasso and his family. Created with his natural warmth, hospitality and community ties, Hacienda Zuleta is blessed with beautiful antique rooms, delicious home-grown Andean cuisine and a wealth of activities to choose from.
          Visit mountain lakes and vistas on our own breed of horses, find hidden trails with centuries of history by foot or bike, see and learn about endangered species at our Condor Huasi facilities or take up a cookery or local embroidery class - all this and more is on offer to you at Hacienda Zuleta.
          </p>
          
          <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
          <br/><br/>
          <Link to="/ecuador" id="volver">
          <i className="fas fa-arrow-left"></i> Go back</Link>
      </div>

      </div>

      </div>
    );
  }
}

export default Zuleta;