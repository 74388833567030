import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Peru2 extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Peru2">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624393/sa/backgrounds/machu-picchu2.jpg" alt=""/>
      </div>

      <p className="header-title">CUSCO, SACRED VALLEY,<br/> MACHU PICCHU </p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>
                <b>CUSCO:</b> Seductive, striking and natural, Cusco’s history lives in its streets, squares, valleys and towns.Stunning destinations and examples of fine engineering by Inca stonemasons can be seen in Choquequirao, Saysayhuamán, Kenko, Tambomachay, Ollantaytambo and Machu Picchu, the Inca jewel built with the wisdom of the ancient Peruvians in an ecological environment. Captivating landscapes such as the Sacred Valley, where the mountains are clothed by terraces.
                Pictures villages where the past forms part of the present. Cusco really is the birthplace of the world.
                <br/><br/>
                <b>MACHU PICCHU:</b> Machu Picchu is an Incan city surrounded by temples, terraces and water channels, built on a mountaintop. It was built with huge blocks of stone joined to each other without any mortar. Today it has been designated cultural heritage of humanity in recognition of its political, religious and administrative importance during the age of the Incas.
                <br/><br/>
                <b>SACRED VALLEY:</b> The valley’s rich history and culture make it one of our favourite places in Peru. It’s also a great place to acclimatize to the altitude in Cusco as the Sacred Valley is lower down and gives tourists the time they need for their bodies to adjust. So, if you’re thinking of visiting Peru, then the Sacred Valley should definitely be on your list of places to visit.
              </p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/south-america" id="volver">
              <i className="fas fa-arrow-left"></i> Go back</Link>
          </div>

      </div>
      </div>
    );
  }
}

export default Peru2;