import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Go6 extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Go6">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624394/sa/backgrounds/go6.jpg" alt=""/>
      </div>

      <p className="header-title">ECUADOR, GALAPAGOS, PERU</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>Get the best from Ecuador and Peru !. These are our recommended tours that we have prepared for vacationers that want to have a more complete travel experience. <br/>
              A mixture of an unforgettable luxury cruise in the Galapagos Islands with amazing inland landscapes and cultural experiences in continental Ecuador and Peru visiting the wonder cities of Cuzco and Machu Pichu
              </p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/south-america" id="volver">
              <i className="fas fa-arrow-left"></i> Go back</Link>
          </div>

      </div>
      </div>
    );
  }
}

export default Go6;