import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Otavalo extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Otavalo">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623914/ecuador/backgrounds/otavalo.jpg" alt=""/>
      </div>

      <p className="header-title">OTAVALO</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION</span>
              <p>Early in the morning our private guide and transport will pick the group planned meeting
              point, we head north of the country, places full of scenic beauty, culture and mystery.
              Otavalo is a town in the province of Imbabura, in Ecuador; located 110 kilometers north of
              the city of Quito. Otavalo was been declared as "intercultural Capital of Ecuador" for being
              a city with enormous potential in several aspects. It has charming landscapes, rich culture,
              and history and business development. Otavalo is the largest indigenous market in South
              America. Ponchos Square or Square Centenerio is colorful South America's largest craft
              market. Return to hotel.</p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go back</Link>
          </div>

      </div>
      </div>
    );
  }
}

export default Otavalo;