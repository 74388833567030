import React, { Component } from 'react';
import '../../App.css';

import { Link } from 'react-router-dom'

import Slider from "react-slick";

class cormorant extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    var settings = {
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      useCSS: true,
      centerMode: true,
      focusOnSelect: true,
      dots: false
    };
    return (
      <div className="Hotel">
            <div id="fullscreen">
              <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621986/cruceros/backgrounds/cormorant.jpg" alt='' id='ship' /> 

              <Link to="/evolution" class="fas fa-angle-right"></Link>
              <Link to="/coral" class="fas fa-angle-left"></Link>

              <div id="text-container">
                <p id="title-hotel">CORMORANT CATAMARAN</p>
                <div id="subtitle-container">
                  <span id="texto-descripcion">
                  16 guests Galapagos Yacht
                  </span>
                </div>
              </div>

            </div>

            <div id="separacion-ficha"></div>

            <div id="slider-cruiser">
            <Slider id="slidershow-cruiser" {...settings}>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622755/cruceros/carrusel/cormorant/5.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622754/cruceros/carrusel/cormorant/4.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622754/cruceros/carrusel/cormorant/7.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622753/cruceros/carrusel/cormorant/6.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622753/cruceros/carrusel/cormorant/2.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622753/cruceros/carrusel/cormorant/3.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622752/cruceros/carrusel/cormorant/1.jpg" alt=''/>
              </div>
            </Slider>
            </div><br/><br/><br/>

            <div id="ficha-tecnica">
              <span>THE CATAMARAN:</span>
              <p>This beautiful 16 passenger vessel has been recently remodeled offering to your clients the following features: All cabins and suites with panoramic ceiling to floor windows and private balconies, 2 comfortable suites
                 with a spacious lounge, four interconnected cabins on the upper deck. Beautiful dinning area with panoramic windows, renovated sun deck with a comfortable al fresco dining
                 and an 8 passenger jacuzzi on the stern area.</p>
              
              <br/><br/>
              <div id="details-pdf">
                  <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
                  </div>
                  <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
                  </div>
            </div>
    
      </div>
    );
  }
}

export default cormorant;