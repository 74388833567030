import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class SantacruzMindo extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Baños">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536785149/ecuador/backgrounds/SantacruzMindo.png" alt=""/>
      </div>

      <p className="header-title">SANTA CRUZ + MINDO</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>Santa Cruz Cruise (5D - 4N) + Mindo cloud forest full day </p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go Back</Link>
          </div>

      </div>

      </div>
    );
  }
}

export default SantacruzMindo;