import React, { Component } from 'react';
import '../../App.css';

import $ from "jquery";

import { Link } from 'react-router-dom'

import logomenu from '../../img/logonav1.png'

class navigation extends Component {

  scroll = ()=>{
    window.scrollTo({
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
      });
  }

  render() {
    $(document).ready(function(){
      $('a').click(function(){
          $('a').removeClass("active");
          $(this).addClass("active");
      });
    });


    $(document).ready(function(){
      $("a").on('click', function(event) {
        if (this.hash !== "") {
          event.preventDefault();
          var hash = this.hash;
          $('html, body').animate({
            scrollTop: $(hash).offset().top
          }, 800, function(){
            window.location.hash = hash;
          });
        }
      });
    });
    return (
      <div className="nav">    
        <div id="nav">
                <div id="links">
                    <Link to="/about">ABOUT US</Link>
                    <Link to="/ecuador">TRAVEL ECUADOR</Link>
                    <Link to="/south-america">SOUTH AMERICA</Link>
                    <Link to="/">HOME</Link>
                    <Link to="/"><img src={logomenu} onClick={()=>this.scroll()} alt=""/></Link>
                </div>
        </div>
      </div>
    );
  }
}

export default navigation;