import React, { Component } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';


import hotel1 from '../../img/mp1.jpg'
import hotel3 from '../../img/calafate.jpg'
import more from '../../img/more.png'
import division from '../../img/division.png'


class hotel2 extends Component {
    render() {
        return (
            <div className="cruise">
                <div id="fullsectioncruise">
                    <div id="container">
                    <ScrollAnimation animateIn="slideInRight" duration={1.5} animateOnce={true}>
                        <div id="img-container">
                            <img src={hotel1} alt='' width="50%" height="100%" />
                            <img src={hotel3} alt='' width="50%" height="100%" />
                        </div>
                    </ScrollAnimation>

                        <div id="info-container">
                            <span>SOUTH AMERICA</span>
                            <p id="infohotel">Nestled between the Caribbean, the South Pacific, and the South Atlantic Oceans, South America is the wilder of the Americas, and a continent of superlatives.
                            <br/><br/>
                            The world's biggest rainforest and the largest river (Amazon), an astonishing mountain range (the Andes), remote islands (Galapagos - Easter Islands), heavenly beaches, wide deserts (Atacama), icy landscapes (Patagonia & Tierra del Fuego). Take the chance and discover these marvelous sites, as well as several other breathtaking natural attractions.</p>
                            <Link to="/south-america">
                            <img id="more" src={more} alt="" width="40" height="58" />
                            </Link><br />
                            <img id="division2" src={division} alt="" />
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default hotel2;