import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Finch extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Baños">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623837/ecuador/backgrounds/finch.jpg" alt=""/>
      </div>

      <p className="header-title">FINCH BAY HOTEL</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>The Finch Bay Galapagos Hotel, a proud member of National Geographic Unique Lodges of the WorldTM, is your Galapagos exploration headquarters, a beachfront base from which to discover the wildlife of the islands aboard their private yacht, and create your own adventures on land and on sea. The perfect complement to a Galapagos cruise, or a destination on its own, a stay at the Finch Bay allows you to feel the islands’ essence.</p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go Back</Link>
          </div>

      </div>

      </div>
    );
  }
}

export default Finch;