import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Quilotoa extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Quilotoa">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623936/ecuador/backgrounds/quilotoa2.jpg" alt=""/>
      </div>

      <p className="header-title">QUILOTOA</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>Early in the morning our private guide and transport will pick the group planned meeting
                point, we will head through the Avenue of the Volcanoes.
                Quilotoa lagoon, which is one of the most beautiful and spectacular places, a quiet,
                fascinating green and turquoise lake located in an extinct volcanic crater. It's great to walk
                down and reach the edge of the lagoon is a perfect place to relax and enjoy the tranquility</p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go back</Link>
          </div>

      </div>
      </div>
    );
  }
}

export default Quilotoa;