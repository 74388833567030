import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Mompiche extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Baños">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623925/ecuador/backgrounds/mompiche.jpg" alt=""/>
      </div>

      <p className="header-title">DECAMERON MOMPICHE</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>Located in the province of Esmeraldas Ecuador, 2 hours from Esmeraldas Airport. This
              destination has an indescribable natural beauty to be surrounded by humid tropical forests,
              ecological reserves and extensive beaches with a favorable warm climate that accompanies
              the region all year round. The hotel has a modern tropical design blending with the natural
              environment of the area. It has 282 rooms, 3 specialized restaurants and 1 buffet, 6 bars, a
              nightclub, 5 swimming pools, and a convention center with capacity for 600 people, equipped
              with the best technology and Business Center. 
              </p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go back</Link>
          </div>

      </div>

      </div>
    );
  }
}

export default Mompiche;