import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Baños extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Baños">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623826/ecuador/backgrounds/ba%C3%B1os.jpg" alt=""/>
      </div>

      <p className="header-title">BAÑOS</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>Commonly referred to as Baños, is a city in eastern Tungurahua Province, in central Ecuador. Baños is the second most populous city in Tungurahua, after Ambato, the capital, and is a major tourist center. It is known as the "Gateway to the Amazon", as it's the last big city still located in the mountains before reaching the jungle and other towns that are located in the Amazon River basin.</p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go Back</Link>
          </div>

      </div>

      </div>
    );
  }
}

export default Baños;