import React, { Component } from 'react';
import '../../App.css';

import video from '../../video/video.mp4'

class banner extends Component {

    componentDidMount(){

        // document.getElementById('cuadro').style.visibility='hidden';
        //document.getElementById('cuadro-min').style.visibility='visible';

        document.getElementById('muted').style.visibility='visible';
        document.getElementById('unmuted').style.visibility='hidden';

        document.getElementById('videobanner').play();
    }

    scroll = ()=>{
        window.scrollTo({
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
          });
    }

    togglePlay = ()=>{
        var video = document.getElementById('videobanner')
        if(video.muted){
            video.muted = false;
            document.getElementById('muted').style.visibility='hidden';
            document.getElementById('unmuted').style.visibility='visible';
        } else {
            video.muted = true;
            document.getElementById('muted').style.visibility='visible';
            document.getElementById('unmuted').style.visibility='hidden';
        }
    } 

  render() {
    return (
      <div className="banner">    
        <div id="fullsection">
                
            <div id="video">
                    <video id="videobanner" autoPlay="true" preload='auto' muted loop playsInline>
                        <source src={video} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                    <i className="fas fa-volume-off" id="muted" onClick={()=>this.togglePlay()}></i>
                    <i className="fas fa-volume-up" id="unmuted"onClick={()=>this.togglePlay()}></i>
            </div>

        </div>
      </div>
    );
  }
}

export default banner;