import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Guayaquil extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Guayaquil">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623859/ecuador/backgrounds/guayaquil.jpg" alt=""/>
      </div>

      <p className="header-title">GUAYAQUIL EXPRESS</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>Guayaquil is the largest and the most populous city in Ecuador, with around 2 million people in the metropolitan area, as well as the nation's main port. The city is the capital of the province of Guayas and the seat of the namesake canton.
                 Guayaquil is located on the western bank of the Guayas River, which flows into the Pacific Ocean at the Gulf of Guayaquil.</p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go back</Link>
          </div>

      </div>
      </div>
    );
  }
}

export default Guayaquil;