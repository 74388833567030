import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Easter extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Easter">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624372/sa/backgrounds/easter.jpg" alt=""/>
      </div>

      <p className="header-title">EASTER ISLAND</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>Easter Island is known by the local people as Rapa Nui. In
              Spanish the island is called Isla de Pascua.
              Easter Island is located in the middle of the Pacific Ocean about
              3,700km from the Chilean Coast making it the most isolated
              inhabited island in the world. With its petroglyphs and imposing
              Moai statues, the island is like an outdoor museum that cannot
              be found anywhere else.
              Here there are 7 statues representing the original explorers that
              came to this island and that are now looking out to sea towards
              their homeland.</p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/south-america" id="volver">
              <i className="fas fa-arrow-left"></i>Go back</Link>
          </div>

      </div>
      </div>
    );
  }
}

export default Easter;