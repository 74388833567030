import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Centinela extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Baños">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623822/ecuador/backgrounds/centinela.jpg" alt=""/>
      </div>

      <p className="header-title">DECAMERON PUNTA CENTINELA</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION</span>
              <p>It is located in the Province of Santa Elena, one of the most exclusive areas on the Ecuadorian
              coastal profile, about 2 hours by land from Guayaquil Airport. Punta Centinela is one of the
              most beautiful beaches in Ecuador and is an ideal place that offers extensive recreational
              opportunities to enjoy the beach, the marine fauna and also the exquisite national and
              international cuisine that is lived there. This resort has 197 comfortable rooms, 3 buffet and a
              la carte restaurants, 4 bars, nightclub, 2 large swimming pools, sports courts and a convention
              center with capacity for 400 people, equipped with the best technology for the development
              of events. 
              </p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go Back</Link>
          </div>

      </div>

      </div>
    );
  }
}

export default Centinela;