import React, { Component } from 'react';
import '../../App.css';

import { Link } from 'react-router-dom'

import Slider from "react-slick";

class infinity extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      useCSS: true,
      centerMode: true,
      focusOnSelect: true
    };
    return (
      <div className="Hotel">
            <div id="fullscreen">
              <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621912/cruceros/backgrounds/infinity.jpg" alt='' id='ship' /> 
              <Link to="/isabela" class="fas fa-angle-right"></Link>
              <Link to="/gran-majestic" class="fas fa-angle-left"></Link>
              <div id="text-container">
                <p id="title-hotel">INFINITY YACHT</p>
                <div id="subtitle-container">
                  <span id="texto-descripcion">
                  20 guests Galapagos Yacht
                  </span>
                </div>
              </div>

            </div>

            <div id="separacion-ficha"></div>

            <div id="slider-cruiser">
            <Slider id="slidershow-cruiser" {...settings}>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622968/cruceros/carrusel/infinity/9.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622967/cruceros/carrusel/infinity/8.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622967/cruceros/carrusel/infinity/11.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622966/cruceros/carrusel/infinity/10.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622966/cruceros/carrusel/infinity/7.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622966/cruceros/carrusel/infinity/3.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622965/cruceros/carrusel/infinity/6.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622963/cruceros/carrusel/infinity/1.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622963/cruceros/carrusel/infinity/4.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622963/cruceros/carrusel/infinity/5.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622962/cruceros/carrusel/infinity/2.jpg" alt=''/>
              </div>
            </Slider>
            </div><br/><br/><br/>

            <div id="ficha-tecnica">
              <span>THE YACHT:</span>
              <p>All eight cabins and two suites have private balconies. The Infinity Luxury Yacht exceeds everything seen before. Offering high performance, unrivalled comfort,<br/>
               respect for the environment and stunning style. Outside dining and bar and also a solarium allow you to<br/>
               cruise the Galapagos in tremendous style and sumptuous luxury.
              </p>
              
              <br/><br/>
              <div id="details-pdf">
                  <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
                  </div>
                  <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
                  </div>
            </div>
    
      </div>
    );
  }
}

export default infinity;