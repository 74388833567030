import React, { Component } from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'

import { AnimatedRoute } from 'react-router-transition';

// main pages
import Home from './components/home'
import Ecuador from './components/ecuador'
import SouthAmerica from './components/south-america'
import About from './components/about'

// secondary pages
import Sacha from './components/ecuador/paquetes/sacha'
import Selva from './components/ecuador/paquetes/selva'
import Napo from './components/ecuador/paquetes/napo'
import Suizo from './components/ecuador/paquetes/suizo'
import Anakonda from './components/ecuador/paquetes/anakonda'
import Baños from './components/ecuador/paquetes/baños'
import Cotopaxi from './components/ecuador/paquetes/cotopaxi'
import Quilotoa from './components/ecuador/paquetes/quilotoa'
import Otavalo from './components/ecuador/paquetes/otavalo'
import Guayaquil from './components/ecuador/paquetes/guayaquil'
import Cuenca from './components/ecuador/paquetes/cuenca'
import Quito from './components/ecuador/paquetes/quito'
import Mashpi from './components/ecuador/paquetes/mashpi'
import Zuleta from './components/ecuador/paquetes/zuleta'
import Mindo from './components/ecuador/paquetes/mindo'
import Coffe from './components/ecuador/paquetes/coffe'
import Papallacta from './components/ecuador/paquetes/papallacta'
import Papallacta2 from './components/ecuador/paquetes/papallacta2'
import Mompiche from './components/ecuador/paquetes/mompiche'
import Centinela from './components/ecuador/paquetes/centinela'
import Finch from './components/ecuador/paquetes/finch'
import Mangrove from './components/ecuador/paquetes/mangrove'
import LegendCotopaxi from './components/ecuador/paquetes/LegendCotopaxi'
import SantacruzMindo from './components/ecuador/paquetes/SantacruzMindo'
import PetrelOtavalo from './components/ecuador/paquetes/PetrelOtavalo'

import Peru1 from './components/south-america/paquetes/peru1'
import Peru2 from './components/south-america/paquetes/peru2'
import Peru3 from './components/south-america/paquetes/peru3'
import Easter from './components/south-america/paquetes/easter'
import Go6 from './components/south-america/paquetes/go6'
import Atacama from './components/south-america/paquetes/atacama'
import Calafate from './components/south-america/paquetes/calafate'

// components
import Navigationbar from './components/home/navbar'

// cruceros
import Ship1 from './components/cruceros/alia-catamaran'
import Ship2 from './components/cruceros/coral'
import Ship3 from './components/cruceros/cormorant-catamaran'
import Ship4 from './components/cruceros/evolution-yacht'
import Ship5 from './components/cruceros/galapagos-sky'
import Ship7 from './components/cruceros/grace-yacht'
import Ship8 from './components/cruceros/gran-majestic'
import Ship9 from './components/cruceros/infinity-yacht'
import Ship10 from './components/cruceros/isabela-yacht'
import Ship11 from './components/cruceros/legend-cruise'
import Ship13 from './components/cruceros/natural-paradise-yacht'
import Ship14 from './components/cruceros/nortada'
import Ship15 from './components/cruceros/ocean-spray-catamaran'
import Ship16 from './components/cruceros/petrel-catamaran'
import Ship17 from './components/cruceros/pinta-yacht'
import Ship18 from './components/cruceros/reina-silvia'
import Ship19 from './components/cruceros/santa-cruz-cruise'
import Ship20 from './components/cruceros/camila'

class App extends Component {

  // minimizarcuadro = ()=>{
  //   document.getElementById('cuadro').style.visibility='hidden';
  //   document.getElementById('cuadro-min').style.visibility='visible';
  // }

  // maximizarcuadro = ()=>{
  //     document.getElementById('cuadro').style.visibility='visible';
  //     document.getElementById('cuadro-min').style.visibility='hidden';
  // }

  OpenTab = () => {
    window.open('https://www.e-agencias.com.ec/viexpa', '_blank');
  }

  render() {
    return (
      <Router>
    <div>
      <Navigationbar/>

      <Route exact path="/" component={Home}/>
      <Route path="/ecuador" component={Ecuador}/>
      <Route path="/south-america" component={SouthAmerica}/>
      <Route path="/about" component={About}/>

      <Route path="/sacha" component={Sacha}/>
      <Route path="/suizo" component={Suizo}/>
      <Route path="/selva" component={Selva}/>
      <Route path="/anakonda" component={Anakonda}/>
      <Route path="/napo" component={Napo}/>
      <Route path="/baños" component={Baños}/>
      <Route path="/cotopaxi" component={Cotopaxi}/>
      <Route path="/otavalo" component={Otavalo}/>
      <Route path="/papallacta" component={Papallacta}/>
      <Route path="/papallacta2" component={Papallacta2}/>
      <Route path="/mindo" component={Mindo}/>
      <Route path="/mashpi" component={Mashpi}/>
      <Route path="/zuleta" component={Zuleta}/>
      <Route path="/guayaquil" component={Guayaquil}/>
      <Route path="/quilotoa" component={Quilotoa}/>
      <Route path="/cuenca" component={Cuenca}/>
      <Route path="/quito" component={Quito}/>
      <Route path="/coffe" component={Coffe}/>
      <Route path="/mompiche" component={Mompiche}/>
      <Route path="/centinela" component={Centinela}/>
      <Route path="/finch" component={Finch}/>
      <Route path="/mangrove" component={Mangrove}/>
      <Route path="/LegendCotopaxi" component={LegendCotopaxi}/>
      <Route path="/SantacruzMindo" component={SantacruzMindo}/>
      <Route path="/PetrelOtavalo" component={PetrelOtavalo}/>

      <Route path="/peru1" component={Peru1}/>
      <Route path="/peru2" component={Peru2}/>
      <Route path="/peru3" component={Peru3}/>
      <Route path="/easter" component={Easter}/>
      <Route path="/go6" component={Go6}/>
      <Route path="/atacama" component={Atacama}/>
      <Route path="/calafate" component={Calafate}/>
      
      <AnimatedRoute
      path="/aliacatamaran"
      component={Ship1}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

    <AnimatedRoute
      path="/coral"
      component={Ship2}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

    <AnimatedRoute
      path="/cormorant"
      component={Ship3}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

    <AnimatedRoute
      path="/evolution"
      component={Ship4}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

    <AnimatedRoute
      path="/galapagos"
      component={Ship5}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

    <AnimatedRoute
      path="/grace"
      component={Ship7}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

    <AnimatedRoute
      path="/gran-majestic"
      component={Ship8}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

    <AnimatedRoute
      path="/infinity"
      component={Ship9}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

    <AnimatedRoute
      path="/isabela"
      component={Ship10}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

    <AnimatedRoute
      path="/legend"
      component={Ship11}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

    <AnimatedRoute
      path="/natural"
      component={Ship13}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

    <AnimatedRoute
      path="/nortada"
      component={Ship14}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

    <AnimatedRoute
      path="/ocean"
      component={Ship15}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

    <AnimatedRoute
      path="/petrel"
      component={Ship16}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

    <AnimatedRoute
      path="/pinta"
      component={Ship17}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

    <AnimatedRoute
      path="/reina"
      component={Ship18}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

    <AnimatedRoute
      path="/santa-cruz"
      component={Ship19}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

    <AnimatedRoute
      path="/camila"
      component={Ship20}
      atEnter={{ offset: -100 }}
      atLeave={{ offset: -100 }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    />

      
    </div>

  </Router>
    );
  }
}

export default App;

