import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Atacama extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Easter">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624365/sa/backgrounds/atacama.jpg" alt=""/>
      </div>

      <p className="header-title">ATACAMA DESERT</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>The Atacama Desert is one of the driest places on Earth.
              The Atacama is in the country of Chile in South America.
              In an average year, much of this desert gets less than 1
              millimeter (0.04 inch) of rain! That makes it 50 times drier
              than Death Valley in California. It is hard to survive in the
              Atacama Desert. Few people, animals, plants, or even
              microbes live there. But the desert isn’t completely
              without life. Some people and other living creatures do
              get by in the Atacama.<br/>
              The Atacama is the driest hot desert in the world. There
              are some weather stations in the Atacama where there
              has never been any rain! Not all deserts are hot. </p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/south-america" id="volver">
              <i className="fas fa-arrow-left"></i> Go back</Link>
          </div>

      </div>
      </div>
    );
  }
}

export default Atacama;