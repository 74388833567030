import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Coffe extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Coffe">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623864/ecuador/backgrounds/coffe.jpg" alt=""/>
      </div>

      <p className="header-title">COFFEE EXPERIENCE</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION</span>
              <p>More than just a superior coffee, the Café Frajares, and its varieties Delirio, Amores Finos and
                Alto Espiritu are new experience for the senses. When you try them you will be able to
                perceive a symphony of flavors: floral, toffee, sugar cane, green apple, orange skin, honey,
                red fruits. Wonderful adventure for the palate!
                Coffee is grown in the rich northwestern area of the province of Pichincha in Nanegalito, at
                1,700 meters high. Here the special climatic conditions give this as to each of the varieties of
                Cafe Frajares, surprising flavors and aromas. 
                </p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go Back</Link>
          </div>

      </div>
      </div>
    );
  }
}

export default Coffe;