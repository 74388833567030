import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Cotopaxi extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Cotopaxi">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623846/ecuador/backgrounds/cotopaxi.jpg" alt=""/>
      </div>

      <p className="header-title">COTOPAXI</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>Cotopaxi is the highest active volcano in the world (5897 meters above sea level or 19388
              feet) and one of the most beautiful snowcapped mountains in Ecuador. We leave Quito at
              8am, taking the Pan-American Highway south and reach the Cotopaxi National Park after
              two hours of travel.
              During the tour we enjoy landscapes and incredible scenery of the Ecuadorian Andes. In the
              park we will visit the interpretive center to learn about the fauna and flora of the area as well
              as important information the wonderful Cotopaxi Volcano (the highest active volcano).
              Then we will walk about an hour visiting the Limpiopungo’s lagoon.
              If you want more adventure, we can coordinate the ascent to the parking of the volcano
              located 4,600 meters and from there take a walk an hour and a half to the refuge Jose Rivas,
              located 200 meters up on a steep slope.
              After the descent back to the city of Quito, with approximate arrival at 5pm. </p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go Back</Link>
          </div>

      </div>
      </div>
    );
  }
}

export default Cotopaxi;