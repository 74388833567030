import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Papallacta extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Papallacta">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623932/ecuador/backgrounds/papallacta.jpg" alt=""/>
      </div>

      <p className="header-title">PAPALLACTA FULL DAY</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>Papallacta is a small village in Napo Province, Ecuador located at an altitude of 3,300 m in the Andes just off the Eastern Cordilleras on the road from Quito which leads into the Amazon jungle. The scenic drive from Quito to Papallacta passes through several towns and small villages before ascending to a peak of over 4,000 m, from where mountains and glaciers are visible. Descending from the peak to Papallacta, the ecosystems transform from alpine to tropical jungle.</p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go back</Link>
          </div>

      </div>
      </div>
    );
  }
}

export default Papallacta;