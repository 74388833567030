import React, { Component } from 'react';
import '../../App.css';

import { Link } from 'react-router-dom'

import Slider from "react-slick";

class camila extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    var settings = {
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 2,
      slidesToScroll: 1,
      useCSS: true,
      centerMode: true,
      focusOnSelect: true,
      dots: false
    };
    return (
      <div className="Hotel">
            <div id="fullscreen">
              <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621955/cruceros/backgrounds/camila.jpg" alt='' id='ship' /> 

              <Link to="/aliacatamaran" class="fas fa-angle-right"></Link>
              <Link to="/santa-cruz" class="fas fa-angle-left"></Link>

              <div id="text-container">
                <p id="title-hotel">CAMILA</p>
                <div id="subtitle-container">
                  <span id="texto-descripcion">
                  16 guests Galapagos Cruise
                  </span>
                </div>
              </div>

            </div>

            <div id="separacion-ficha"></div>

            <div id="slider-cruiser">
            <Slider id="slidershow-cruiser" {...settings}>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622685/cruceros/carrusel/camila/2.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622685/cruceros/carrusel/camila/3.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622684/cruceros/carrusel/camila/1.jpg" alt=''/>
              </div>
            </Slider>
            </div><br/><br/><br/>

            <div id="ficha-tecnica">
              <span id='offset-x-2'>THE CRUISE:</span>
              <p>The best way to see the Galapagos Islands is on board a luxury cruise, and the newly built Camila is a proud addition to our Galapagos fleet. Guests travel in comfort to the different islands, where they have up-close encounters with blue-footed boobies, iguanas, giant tortoises, whales and more.
                The newest ship in Galapagos, the Camila offers a first-class way to visit these magical islands. The Camila is elegant, swift and silent at sea. A motorized trimaran, the Camila is designed for comfort and stability and is outfitted with spacious cabins, a comfortable lounge, and Jacuzzi.
                On the Camila, we emphasize the remarkable Natural History of Galapagos, selecting guides who are knowledgeable about biology and ecology in addition to having excellent language skills and client reviews.</p>
              
              <br/><br/>
                  <div id="details-pdf">
                  <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
                  </div>
                  <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
                  </div>
            </div>
    
      </div>
    );
  }
}

export default camila;