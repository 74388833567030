import React, { Component } from 'react';
import '../../App.css';

import { Link } from 'react-router-dom'

import Slider from "react-slick";

class isabela extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    var settings = {
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      useCSS: true,
      centerMode: true,
      focusOnSelect: true,
      dots: false
    };
    return (
      <div className="Hotel">
            <div id="fullscreen">
              <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621923/cruceros/backgrounds/isabela.jpg" alt='' id='ship' /> 
              <Link to="/legend" class="fas fa-angle-right"></Link>
              <Link to="/infinity" class="fas fa-angle-left"></Link>
              <div id="text-container">
                <p id="title-hotel">ISABELA II</p>
                <div id="subtitle-container">
                  <span id="texto-descripcion">
                  40 guests Galapagos Yacht
                  </span>
                </div>
              </div>

            </div>

            <div id="separacion-ficha"></div>

            <div id="slider-cruiser">
            <Slider id="slidershow-cruiser" {...settings}>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623000/cruceros/carrusel/isabela/5.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622999/cruceros/carrusel/isabela/6.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622999/cruceros/carrusel/isabela/3.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622999/cruceros/carrusel/isabela/4.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622998/cruceros/carrusel/isabela/7.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622998/cruceros/carrusel/isabela/1.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622998/cruceros/carrusel/isabela/2.jpg" alt=''/>
              </div>
            </Slider>
            </div><br/><br/><br/>

            <div id="ficha-tecnica">
              <span>THE YACHT:</span>
              <p>Yacht Isabela II offers the perfect, intimate space for exploring Darwin’s islands in comfort. Traditionalists will enjoy feeling at home with a crew that has remained almost unchanged for years, leading to seamless attention to detail that will make your stay extra special.
                  With just 20 guest cabins, the yacht’s convivial atmosphere leads to a close-up experience of this unique world. On board, guests find delicious cuisine, faultless service and excellent facilities, including a Hut Tub, fitness room, bar and outside deck for al fresco meals, drinks, and sunbathing.
                  Explore the coast with your guides on our pangas, glass-bottom boat or kayaks, or get wet and snorkel.

              </p><br/><br/>
              
              <br/>
              <div id="details-pdf">
                  <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
                  </div>
                  <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
                  </div>
            </div>
    
      </div>
    );
  }
}

export default isabela;