import React, { Component } from 'react';
import '../../App.css';

import { Link } from 'react-router-dom'

class Ecuador extends Component {
  constructor(){
    super()
    this.state = {
      toggleRegion : 0
    }
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if(this.state.toggleRegion === 0){
      document.getElementById("galapagos").style.backgroundColor = "#BFD346"
    }
  }

  render() {
    return (
      <div className="Ecuador">
            <div className="top-header">
            <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623670/ecuador/banner.jpg" alt=""/>
              <div>
                <p id="title-hotel">TRAVEL ECUADOR</p>
                <div id="subtitle-container">
                  <span id="price-text">
                  Galapagos / Amazon / Andes / Coastline
                  </span>
                </div>
                
              </div>
            </div>


            <div className="boxes-region">
              <div className="region" id="galapagos" onClick={()=>{
                                                                  this.setState({toggleRegion: 0})
                                                                  document.getElementById("galapagos").style.backgroundColor="#BFD346"
                                                                  document.getElementById("amazonia").style.backgroundColor="#3074bb"
                                                                  document.getElementById("sierra").style.backgroundColor="#3074bb"
                                                                  document.getElementById("costa").style.backgroundColor="#3074bb"
                                                                  } }>
                <span>Galapagos</span>
              </div>
              <div className="region" id="amazonia" onClick={()=>{
                                                                  this.setState({toggleRegion: 1})
                                                                  document.getElementById("amazonia").style.backgroundColor="#BFD346"
                                                                  document.getElementById("galapagos").style.backgroundColor="#3074bb"
                                                                  document.getElementById("sierra").style.backgroundColor="#3074bb"
                                                                  document.getElementById("costa").style.backgroundColor="#3074bb"
                                                                  } }>
                <span>Amazon</span>
              </div>
              <div className="region" id="sierra" onClick={()=>{
                                                                  this.setState({toggleRegion: 2})
                                                                  document.getElementById("sierra").style.backgroundColor="#BFD346"
                                                                  document.getElementById("amazonia").style.backgroundColor="#3074bb"
                                                                  document.getElementById("galapagos").style.backgroundColor="#3074bb"
                                                                  document.getElementById("costa").style.backgroundColor="#3074bb"
                                                                  } }>
                <span>Andes</span>
              </div>
              <div className="region" id="costa" onClick={()=>{
                                                                  this.setState({toggleRegion: 3})
                                                                  document.getElementById("costa").style.backgroundColor="#BFD346"
                                                                  document.getElementById("amazonia").style.backgroundColor="#3074bb"
                                                                  document.getElementById("sierra").style.backgroundColor="#3074bb"
                                                                  document.getElementById("galapagos").style.backgroundColor="#3074bb"
                                                                  } }>
                <span>Coastline</span>
              </div>
            </div>

            {this.state.toggleRegion === 0 &&
            <div className="middle-section">
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624123/ecuador/thumbnails/finch.jpg" alt=""/>
                <label><Link to="/finch">FINCH BAY HOTEL</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624134/ecuador/thumbnails/mangrove.jpg" alt=""/>
                <label><Link to="/mangrove">RED MANGROVE HOTEL</Link></label>
              </div>
            </div>
            }

            {this.state.toggleRegion === 1 &&
            <div className="middle-section">
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536620717/amazon%C3%ADa/thumb/sacha.jpg" alt=""/>
                <label><Link to="/sacha">SACHA LODGE</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536620719/amazon%C3%ADa/thumb/selva.jpg" alt=""/>
                <label><Link to="/selva">LA SELVA LODGE & SPA</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536620718/amazon%C3%ADa/thumb/napo.jpg" alt=""/>
                <label><Link to="/napo">NAPO WILD LIFE CENTER LODGE</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536620716/amazon%C3%ADa/thumb/suizo.jpg" alt=""/>
                <label><Link to="/suizo">LA CASA DEL SUIZO HOTEL</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536620716/amazon%C3%ADa/thumb/anakonda.jpg" alt=""/>
                <label><Link to="/anakonda">ANAKONDA AMAZON CRUISES</Link></label>
              </div>
            </div>
            }
            
            {this.state.toggleRegion === 2 &&
            <div className="middle-section">
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624133/ecuador/thumbnails/quito.jpg" alt=""/>
                <label><Link to="/quito">CITY TOUR + MIDDLE OF THE WORLD</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624119/ecuador/thumbnails/cuenca.jpg" alt=""/>
                <label><Link to="/cuenca">CUENCA EXPRESS</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624119/ecuador/thumbnails/ba%C3%B1os.jpg" alt=""/>
                <label><Link to="/baños">BAÑOS</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624126/ecuador/thumbnails/cotopaxi.jpg" alt=""/>
                <label><Link to="/cotopaxi">COTOPAXI</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624129/ecuador/thumbnails/otavalo.jpg" alt=""/>
                <label><Link to="/otavalo">OTAVALO</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624133/ecuador/thumbnails/papallacta.jpg" alt=""/>
                <label><Link to="/papallacta">PAPALLACTA 2D - 1N</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624133/ecuador/thumbnails/papallacta.jpg" alt=""/>
                <label><Link to="/papallacta2">PAPALLACTA FULL DAY</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624125/ecuador/thumbnails/mindo.jpg" alt=""/>
                <label><Link to="/mindo">MINDO</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624131/ecuador/thumbnails/quilotoa.jpg" alt=""/>
                <label><Link to="/quilotoa">QUILOTOA</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624122/ecuador/thumbnails/mashpi.jpg" alt=""/>
                <label><Link to="/mashpi">MASHPI</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624134/ecuador/thumbnails/zuleta.jpg" alt=""/>
                <label><Link to="/zuleta">ZULETA</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624286/ecuador/thumbnails/coffe1.jpg" alt=""/>
                <label><Link to="/coffe">COFFE EXPERIENCE</Link></label>
              </div>
            </div>
            }

            {this.state.toggleRegion === 3 &&
            <div className="middle-section">
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624121/ecuador/thumbnails/guayaquil.jpg" alt=""/>
                <label><Link to="/guayaquil">GUAYAQUIL EXPRESS</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624136/ecuador/thumbnails/mompiche.png" alt=""/>
                <label><Link to="/mompiche">DECAMERON MOMPICHE</Link></label>
              </div>
              <div className="middle-section-item">
                <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536624117/ecuador/thumbnails/centinela.jpg" alt=""/>
                <label><Link to="/centinela">DECAMERON PUNTA CENTINELA</Link></label>
              </div>
            </div>
            }
      </div>
    );
  }
}

export default Ecuador;