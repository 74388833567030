import React, { Component } from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom'

class Napo extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Baños">
      <div className="background">
        <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621686/amazon%C3%ADa/napo2.jpg" alt=""/>
      </div>

      <p className="header-title">NAPO WILDLIFE CENTER LODGE</p>
      <div className="ecuador-pack-container">

          <div id="ficha-tecnica">
              <span id='offset-x-2'>DESCRIPTION:</span>
              <p>The luxurious Napo Wildlife Center is nestled on a small ridge at the edge of a great jungle lake surrounded by beautiful pristine Amazon rainforest. Designed for nature lovers who want to experience the Amazon's unique landscapes and observe beautiful wildlife. Spanning nearly 2.5 million acres of lush Amazonian rainforest, Ecuador’s Yasuní National Park is also a UNESCO Biosphere Reserve. This is a mesmerizing place unlike any other! The Napo Wildlife Center is the only facility of its kind located within the vibrant heart of one of the most biodiverse ecosystems on our planet.</p>
              
              <br/><br/>
              <div id="details-pdf">
              <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
              </div>
              <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
              </div>
              <br/><br/>
              <Link to="/ecuador" id="volver">
              <i className="fas fa-arrow-left"></i> Go Back</Link>
          </div>

      </div>

      </div>
    );
  }
}

export default Napo;