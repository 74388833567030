import React, { Component } from 'react';
import '../../App.css';

import { Link } from 'react-router-dom'

import Slider from "react-slick";

class ocean extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    var settings = {
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      useCSS: true,
      centerMode: true,
      focusOnSelect: true,
      dots: false
    };
    return (
      <div className="Hotel">
            <div id="fullscreen">
              <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621940/cruceros/backgrounds/ocean.jpg" alt='' id='ship' /> 
              <Link to="/petrel" class="fas fa-angle-right"></Link>
              <Link to="/nortada" class="fas fa-angle-left"></Link>
              <div id="text-container">
                <p id="title-hotel">OCEAN SPRAY CATAMARAN</p>
                <div id="subtitle-container">
                  <span id="texto-descripcion">
                  16 guests Galapagos Yacht
                  </span>
                </div>
              </div>

            </div>

            <div id="separacion-ficha"></div>

            <div id="slider-cruiser">
            <Slider id="slidershow-cruiser" {...settings}>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623225/cruceros/carrusel/ocean/20.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623223/cruceros/carrusel/ocean/19.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623223/cruceros/carrusel/ocean/12.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623222/cruceros/carrusel/ocean/15.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623220/cruceros/carrusel/ocean/16.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623219/cruceros/carrusel/ocean/17.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623218/cruceros/carrusel/ocean/18.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623214/cruceros/carrusel/ocean/14.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623209/cruceros/carrusel/ocean/13.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623209/cruceros/carrusel/ocean/11.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623206/cruceros/carrusel/ocean/5.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623204/cruceros/carrusel/ocean/8.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623200/cruceros/carrusel/ocean/6.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623199/cruceros/carrusel/ocean/9.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623199/cruceros/carrusel/ocean/10.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623193/cruceros/carrusel/ocean/7.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623193/cruceros/carrusel/ocean/2.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623193/cruceros/carrusel/ocean/1.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623190/cruceros/carrusel/ocean/3.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623187/cruceros/carrusel/ocean/4.jpg" alt=''/>
              </div>
            </Slider>
            </div><br/><br/><br/>

            <div id="ficha-tecnica">
              <span>THE CATAMARAN:</span>
              <p>The new Mega-Catamaran Ocean Spray offers the best performance and comfort in Galapagos Cruising. The elegant design
               includes a huge sundeck, Jacuzzi and spacious cabins each with private balcony. Her speed, comfort, top guides and itinerary
                will make Ocean Spray perfect for your luxury Galapagos cruise. Ample social areas and Jacuzzi All cabins with Private Balconies
                 Bilingual Naturalist Guide Quality Control Manager Kayaks on board
              </p><br/><br/>
              
              <br/>
              <div id="details-pdf">
                  <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
                  </div>
                  <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
                  </div>
            </div>
    
      </div>
    );
  }
}

export default ocean;