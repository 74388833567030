import React, { Component } from 'react';
import '../../App.css';

import { Link } from 'react-router-dom'

import Slider from "react-slick";

class coral extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    var settings = {
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      useCSS: true,
      centerMode: true,
      focusOnSelect: true,
      dots: false
    };
    return (
      <div className="Hotel">

            <Link to="/cormorant" class="fas fa-angle-right"></Link>
            <Link to="/aliacatamaran" class="fas fa-angle-left"></Link>

            <div id="fullscreen">
              <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621981/cruceros/backgrounds/coral.jpg" alt='' id='ship' /> 

              <div id="text-container">
                <p id="title-hotel">CORAL I & II</p>
                <div id="subtitle-container">
                  <span id="texto-descripcion">
                  36 & 20 guests Galapagos Yacht
                  </span>
                </div>
              </div>

            </div>

            <div id="separacion-ficha"></div>

            <div id="slider-cruiser">
            <Slider id="slidershow-cruiser" {...settings}>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622717/cruceros/carrusel/coral/13.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622715/cruceros/carrusel/coral/4.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622715/cruceros/carrusel/coral/7.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622715/cruceros/carrusel/coral/12.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622714/cruceros/carrusel/coral/11.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622713/cruceros/carrusel/coral/10.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622712/cruceros/carrusel/coral/8.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622711/cruceros/carrusel/coral/9.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622711/cruceros/carrusel/coral/2.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622708/cruceros/carrusel/coral/1.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622708/cruceros/carrusel/coral/5.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622708/cruceros/carrusel/coral/6.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536622707/cruceros/carrusel/coral/3.jpg" alt=''/>
              </div>
            </Slider>
            </div><br/><br/><br/>

            <div id="ficha-tecnica">
              <span>THE VESSEL:</span>
              <p>The Coral I & II are gorgeous, comfortable, sister yachts offering charming social areas distributed on three decks, with both outdoor and interior spaces allowing you to enjoy an intimate expedition experience in your own way. With a capacity for 36 and 20 guests respectively, the vessels offer open deck areas with a Jacuzzi to make the best of your relaxing time while cruising the islands. The interiors are thoughtfully designed to resemble a private yacht experience in a cozy ambiance. The restaurant serves delicious Ecuadorian and international cuisine even extending to a BBQ dinner on the open-air Moon Deck. They are the perfect cruise yachts for charter groups where the thrill and comfort are guaranteed.
              </p><br/><br/>
              
              <br/>
                  <div id="details-pdf">
                  <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
                  </div>
                  <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
                  </div>
            </div>
      </div>
    );
  }
}

export default coral;