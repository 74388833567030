import React, { Component } from 'react';
import '../../App.css';

import { Link } from 'react-router-dom'

import Slider from "react-slick";

class santacruz extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    var settings = {
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      useCSS: true,
      centerMode: true,
      focusOnSelect: true,
      dots: false
    };
    return (
      <div className="Hotel">
            <div id="fullscreen">
              <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536621960/cruceros/backgrounds/santacruz.jpg" alt='' id='ship' /> 
              <Link to="/camila" class="fas fa-angle-right"></Link>
              <Link to="/reina" class="fas fa-angle-left"></Link>
              <div id="text-container">
                <p id="title-hotel">SANTA CRUZ</p>
                <div id="subtitle-container">
                  <span id="texto-descripcion">
                  90 guests Galapagos Yacht
                  </span>
                </div>
              </div>

            </div>

            <div id="separacion-ficha"></div>

            <div id="slider-cruiser">
            <Slider id="slidershow-cruiser" {...settings}>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623577/cruceros/carrusel/santacruz/11.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623576/cruceros/carrusel/santacruz/3.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623576/cruceros/carrusel/santacruz/8.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623576/cruceros/carrusel/santacruz/2.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623576/cruceros/carrusel/santacruz/10.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623576/cruceros/carrusel/santacruz/9.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623576/cruceros/carrusel/santacruz/5.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623575/cruceros/carrusel/santacruz/1.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623575/cruceros/carrusel/santacruz/7.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623575/cruceros/carrusel/santacruz/4.jpg" alt=''/>
              </div>
              <div>
                 <img src="https://res.cloudinary.com/dcbmvfiwz/image/upload/v1536623574/cruceros/carrusel/santacruz/6.jpg" alt=''/>
              </div>
            </Slider>
            </div><br/><br/><br/>

            <div id="ficha-tecnica">
              <span>THE VESSEL:</span>
              <p>Conceived for operations in remote areas, our state-of-the-art expedition vessel affords the perfect infrastructure for Galapagos exploration. At just over 70 metres long, the generous, 5-deck craft holds 50 cabins for 90 guests and features ample, luxury-sized social areas indoors and out. This infrastructure and the crew’s heritage of decades of experience working in the islands allow guests to make the most of their Galapagos cruise experience.
              </p><br/><br/>
              
              <br/>
              <div id="details-pdf">
                  <a href="https://wa.me/593999144148?text=I%20would%20like%20to%20know%20more" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i> Chat with us</a>
                  </div>
                  <div id="details-pdf">
                  <a href="mailto:amoncayo@viexpa.com?subject=BOOK REQUEST&body=Hello, I want to book one of your trips."><i className="far fa-envelope"></i> Contact us</a>
                  </div>
            </div>
    
      </div>
    );
  }
}

export default santacruz;